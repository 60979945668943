import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Navigation from './components/Navigation';
import Dashboard from './pages/Dashboard';
import Methodology from './pages/Methodology';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navigation />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/metode" element={<Methodology />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
