import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  SvgIcon,
} from '@mui/material';

// Minimalist compass logo
const LogoIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M12,2L9.1,9.1L2,12L9.1,14.9L12,22L14.9,14.9L22,12L14.9,9.1L12,2M12,7L13.5,11L17,12.5L13.5,14L12,18L10.5,14L7,12.5L10.5,11L12,7Z"
    />
  </SvgIcon>
);

const Navigation = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <AppBar 
      position="sticky" 
      elevation={0}
      sx={{ 
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)' 
      }}
    >
      <Container maxWidth="lg">
        <Toolbar 
          sx={{ 
            justifyContent: 'space-between',
            minHeight: '64px',
            px: { xs: 2, sm: 4 },
          }}
        >
          <Button
            component={RouterLink}
            to="/"
            sx={{
              color: 'primary.main',
              fontSize: { xs: '1.25rem', sm: '1.375rem' },
              textTransform: 'none',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              letterSpacing: '-0.02em',
              '&:hover': {
                backgroundColor: 'transparent',
                color: 'primary.dark',
              },
            }}
          >
            <LogoIcon sx={{ fontSize: '2rem' }} />
            Mediekompasset
          </Button>

          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            '& .MuiButton-root': {
              color: theme => isActive(location.pathname) ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.87)',
              visibility: 'visible',
              opacity: 1
            }
          }}>
            <Button
              component={RouterLink}
              to="/"
              color={isActive('/') ? 'primary' : 'inherit'}
              sx={{
                fontWeight: isActive('/') ? 600 : 400,
                textTransform: 'none',
                fontSize: '1rem',
                visibility: 'visible !important',
              }}
            >
              Forside
            </Button>
            <Button
              component={RouterLink}
              to="/metode"
              color={isActive('/metode') ? 'primary' : 'inherit'}
              sx={{
                fontWeight: isActive('/metode') ? 600 : 400,
                textTransform: 'none',
                fontSize: '1rem',
                visibility: 'visible !important',
              }}
            >
              Metode
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
