import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Button,
  CircularProgress,
  Paper,
} from '@mui/material';
import MediaOverview from '../components/MediaOverview';

const Dashboard = () => {
  const [mediaStats, setMediaStats] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Alle');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWithRetry = async (retries = 3, delay = 2000) => {
      for (let i = 0; i < retries; i++) {
        try {
          console.log(`Attempt ${i + 1} of ${retries}`);
          const url = 'https://v3mediekompasset.onrender.com/api/stats';
          console.log('Making request to:', url);
          
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'omit'
          });
          
          if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response status:', response.status);
            console.error('Error response text:', errorText);
            console.error('Response headers:', Object.fromEntries(response.headers));
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log('Data received:', data);
          
          if (!data || !data.media_stats) {
            throw new Error('Invalid data format received');
          }
          
          setMediaStats(data.media_stats);
          setCategories(['Alle', ...(data.categories ? Object.keys(data.categories) : [])]);
          setError(null);
          setLoading(false);
          return; // Success, exit the retry loop
        } catch (error) {
          console.error(`Attempt ${i + 1} failed:`, error);
          if (i === retries - 1) {
            // Last attempt failed
            setError(`Could not load media statistics. Please try again later. (${error.message})`);
            setLoading(false);
          } else {
            // Wait before retrying
            await new Promise(resolve => setTimeout(resolve, delay));
          }
        }
      }
    };

    fetchWithRetry();
  }, []);

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3, textAlign: 'center', color: 'error.main' }}>
          {error}
        </Paper>
      </Container>
    );
  }

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '80vh' 
        }}
      >
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }

  const filteredMediaStats = selectedCategory === 'Alle'
    ? mediaStats
    : Object.fromEntries(
        Object.entries(mediaStats || {}).filter(
          ([_, stats]) => stats.category === selectedCategory
        )
      );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 3,
          background: '#ffffff',
          borderRadius: 2,
        }}
      >
        <Box 
          sx={{ 
            mb: 4,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1.5,
            justifyContent: 'center'
          }}
        >
          {categories.map((category) => (
            <Button
              key={category}
              onClick={() => setSelectedCategory(category)}
              sx={{
                minWidth: 'auto',
                px: 3,
                py: 1,
                borderRadius: '25px',
                textTransform: 'none',
                fontSize: '0.95rem',
                fontWeight: selectedCategory === category ? 600 : 400,
                color: selectedCategory === category ? '#fff' : '#666',
                backgroundColor: selectedCategory === category 
                  ? 'primary.main'
                  : 'transparent',
                border: '1px solid',
                borderColor: selectedCategory === category 
                  ? 'primary.main'
                  : '#e0e0e0',
                boxShadow: selectedCategory === category 
                  ? '0 2px 8px rgba(25, 118, 210, 0.25)'
                  : 'none',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: selectedCategory === category 
                    ? 'primary.dark'
                    : '#f5f5f5',
                  borderColor: selectedCategory === category 
                    ? 'primary.dark'
                    : '#bdbdbd',
                  transform: 'translateY(-1px)',
                  boxShadow: selectedCategory === category 
                    ? '0 4px 12px rgba(25, 118, 210, 0.35)'
                    : '0 2px 4px rgba(0,0,0,0.05)',
                }
              }}
            >
              {category}
            </Button>
          ))}
        </Box>

        <MediaOverview mediaStats={filteredMediaStats} />
      </Paper>
    </Container>
  );
};

export default Dashboard;
